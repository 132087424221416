import { Component, OnInit, Input } from '@angular/core';
import { Transition } from '@uirouter/core';
import { ImageService } from 'src/app/services/image.service';
import { ProductService } from 'src/app/services/product.service';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: "mae-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"]
})
export class ProductComponent implements OnInit {

  @Input() product;

  addToCart: Boolean = false;
  hero: any = {};
  methodologies: any = {};
  htmlText: any = {};
  gallery: any = { images: [] };
  subProducts: any = {};
  technicalSpecifics: any = {};
  resources: any = {
    resources: []
  };
  relatedProducts: any = { products: [] };
  magazineIndex: any = {};
  hasSpecifics: boolean = false

  constructor(
    private transition: Transition,
    private imageService: ImageService,
    private productService: ProductService,
    private metaService: MetaService
  ) { }

  ngOnInit() {

    this
      .setHero()
      .setGalley()
      .setHtmlText()
      .setMagazineIndex()
      .setMethodologies()
      .setRelatedProducts()
      .setResources()
      .setSubProducts()
      .setTechnicalSpecifics();

    this.metaService.setMeta({
      title: this.product && this.product.name ? this.product.name : "M.A.E srl",
      keywords: this.product && this.product.name ? this.product.name : "M.A.E srl",
      description: this.product && this.product.abstract ? this.product.abstract : "",
      image: this.product && this.product.mainImage ? this.product.mainImage : "",
    });
  }

  get datasheetUrl() {
    try {
      return this.productService.pdfUrl(
        this.product.slug,
        this.product.typology.page.slug
      );
    } catch (e) {
      return "";
    }
  }

  private getBradcrumbs() {
    const breadcrumbs = [];

    if (this.product.typology) {
      breadcrumbs.push({
        route: {
          state: "app.page",
          params: {
            slug: `/${this.product.typology.parentPage.slug}/${this.product.typology.page.slug}`
          }
        },
        title: this.product.typology.name
      });
    }

    if (this.product.category) {
      breadcrumbs.push({
        route: {
          state: "app.taxonomy",
          params: {
            taxonomy: this.product.taxonomy.slug,
            category:
              this.transition.params().locale === "it"
                ? "categorie"
                : "categories",
            slug: this.product.category.page.slug
          }
        },
        title: this.product.category.name
      });
    }

    return breadcrumbs;
  }

  private firstGallery() {
    if (this.product.gallery && this.product.gallery.length) {
      return this.product.gallery[0];
    }
  }

  private setHero() {
    this.hero = {
      name: this.product.name,
      title: this.product.abstract,
      image: this.product.mainImage || this.firstGallery(),
      breadcrumbs: this.getBradcrumbs(),
      tags: this.product.tags,
      datasheetUrl: this.datasheetUrl
    };
    return this;
  }

  private setMethodologies() {
    this.methodologies = {
      title:
        this.transition.params().locale === "en"
          ? "Methodologies"
          : "Metodologie",
      color: "yellow",
      blocks: this.product.methodologies.map(met => {
        return {
          title: met.page.title,
          text: met.page.description,
          image: met.page.mainImage
        };
      })
    };
    return this;
  }
  private setHtmlText() {
    this.htmlText = {
      title:
        this.transition.params().locale === "it"
          ? "Descrizione"
          : "Description",
      text: this.product.description
    };
    return this;
  }
  private setGalley() {
    this.gallery = {
      images: this.product.gallery || [],
      title:
        this.transition.params().locale === "it"
          ? "Galleria Immagini"
          : "Image Gallery"
    };
    return this;
    
  }
  private setSubProducts() {

    try {
      if (!this.product.isKit) {
        this.subProducts = {
          title:
            this.transition.params().locale === "it" ? "Varianti" : "Variants",
          products: this.product.subProducts,
          hasSpec: this.setTechnicalSpecifics()
        };
      } else {
        this.subProducts = {
          title:
            this.transition.params().locale === "it"
              ? "Prodotti del kit"
              : "Kit Products",
          products: this.product.kit.map(k => {
            k.product.qty = k.qty;
            return k.product;
          })
        };
      }
    } catch (e) {
      this.subProducts = {
        title: "Prodotti del Kit",
        products: []
      };
    }
    return this;
  }
  private setRelatedProducts() {
    this.relatedProducts = {
      title:
        this.transition.params().locale === "it"
          ? "Prodotti Correlati"
          : "Related Products",
      layout: "grid",
      products: this.product.relatedProducts || []
    };
    return this;
  }

  private setTechnicalSpecifics() {
    this.technicalSpecifics = {
      hasSpec: true,
      title:
        this.transition.params().locale === "it"
          ? "Specifiche Tecniche"
          : "Technical Specifications",
      color: "yellow",
      blocks: (this.product.specifications || []).map(specific => {
        return {
          type: "spec",
          title: specific.section,
          list: specific.values || []
        };
      })
    };
    if (this.technicalSpecifics.blocks.length > 0) {
      this.hasSpecifics = true;
    }
    return this;
  }

  private setResources() {
    this.resources = {
      title: this.transition.params().locale === "it" ? "Risorse" : "Resources",
      resources: (this.product.attachments || []).map(resouce => {
        const file =
          resouce.attachment && resouce.attachment.file
            ? resouce.attachment.file
            : resouce.attachment;
        const path = this.imageService.mediaUrl(file.path);

        Object.assign(file, { path });
        return {
          title: resouce.title,
          file: file
        };
      })
    };
    return this;
  }

  private setMagazineIndex() {
    this.magazineIndex = {
      title: "News",
      news:
        this.product.relatedPages.map(page => {
          return {
            title: page.title,
            short_abstract: page.abstract,
            image: page.mainImage
          };
        }) || []
    };

    return this;
  }
}
