import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: "mae-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"]
})
export class FeedbackComponent implements OnInit {
  @Input() message: string;
  @Output() close = new EventEmitter();
  anim = false;

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.anim = true;
    });
  }

  closeCreate() {
    this.anim = false;
    setTimeout(() => {
      this.close.emit();
    }, 550);
  }
}
