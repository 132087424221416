import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';
import { ProductManager } from '../product-manager';

@Component({
  selector: 'mae-products-index-table',
  templateUrl: './products-index-table.component.html',
  styleUrls: ['./products-index-table.component.scss']
})
export class ProductsIndexTableComponent extends ProductManager implements OnInit {

  @Input() payload: any = {};

  constructor(
    private imageService: ImageService
  ) {
    super();
  }

  ngOnInit() {

    this.products = this.getPagedProducts();

  }

  getUrl(path: string): string {
    return this.imageService.mediaUrl(path);
  }

  static get constructorName() {
    return 'ProductsIndexTableComponent';
  }

}
