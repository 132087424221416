import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api-service/api.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(
    private apiService: ApiService
  ) { }

  show(url: string): Observable<any> {
    return this.apiService.get(`/pages${url}`);
  }

  index(options: any): Observable<any>  {
    return this.apiService.get(`/pages`, options);
  }

}
