import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomepageComponent } from './homepage.component';
import { UIRouterModule } from '@uirouter/angular';
import { PartialModule } from 'src/app/components/partials/partial.module';
import { PageModule } from '../page/page.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    HomepageComponent
  ],
  imports: [

    CommonModule,
    PartialModule,
    PageModule,
    TranslateModule,

    UIRouterModule.forChild({
      states: [
        {
          name: 'app.homepage',
          url: '',
          component: HomepageComponent
        }
      ]
    })

  ]
})
export class HomepageModule { }
