import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import Swiper from "swiper";
import GLightbox from "glightbox";
import { config } from "../../../../../environments/environment";
import { ImageService } from "src/app/services/image.service";
import { nextTick } from "process";

@Component({
  selector: "mae-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnInit, AfterViewInit {
  @Input() payload: any;
  swiper: any = null;
  lightbox: any = null;

  @ViewChild("swiper") swiperEl: ElementRef<HTMLElement>;
  @ViewChild("swiperPagination") swiperPaginationEl: ElementRef<HTMLElement>;

  images = []
  constructor(private imageService: ImageService) {}

  ngOnInit() {
    if (this.payload && this.payload.images && this.payload.images.length) {
      this.payload.images.forEach(element => {
        this.images.push({
          href: this.getPath(element.file.path),
          type: 'image'
        });
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // // if (!this.payload.style || this.payload.style === 'slider') {
      if (
        this.swiperEl &&
        this.swiperEl.nativeElement &&
        this.swiperPaginationEl &&
        this.swiperPaginationEl.nativeElement
      ) {
        this.swiper = new Swiper(this.swiperEl.nativeElement, {
          slidesPerView: 4,
          spaceBetween: 12,
          autoHeight: true,
          pagination: {
            el: this.swiperPaginationEl.nativeElement,
            clickable: true,
          },
          breakpoints: {
            640: {
              slidesPerView: 1,
            },
            767: {
              slidesPerView: 2,
            },
            1023: {
              slidesPerView: 3,
            },
          },
        });
      }
      this.lightbox = GLightbox({
        selector: "gallery",
        type: "image",
        elements: [...this.images]
      });
      // }
    });
  }

  getPath(path: string) {
    return this.imageService.mediaUrl(path);
  }
  // getPath(path: string) {
  //   return this.imageService.sanitizedMediaUrl(path);
  // }

  getBgPath(path: string) {
    return this.imageService.sanitizedBackgroundUrl(path);
  }

  static get constructorName() {
    return "GalleryComponent";
  }
}
