import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api-service/api.service';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(
    private apiService: ApiService
  ) { }

  index(params?): Observable<any> {
    return this.apiService.get(`/attachments/partials?`, {params});
  }

}
