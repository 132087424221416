import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product.component';
import { PartialModule } from 'src/app/components/partials/partial.module';
import { UIRouterModule, Transition } from '@uirouter/angular';
import { ProductService } from 'src/app/services/product.service';
import { AddToCartModule } from 'src/app/components/layout/add-to-cart/add-to-cart.module';

export function productResolve (productService: ProductService, transition: Transition) {
  return productService.show(transition.params().slug, transition.params().category).toPromise();
}

@NgModule({
  declarations: [ProductComponent],
  imports: [

    CommonModule,
    PartialModule,
    AddToCartModule,

    UIRouterModule.forChild({
      states: [
        {
          name: 'app.product',
          url: '/{section: prodotti|products}/:category/:slug',
          component: ProductComponent,
          resolve: [
            {
              token: 'product',
              deps: [ProductService, Transition],
              resolveFn: productResolve
            }
          ]
        }
      ],
    })


  ]
})
export class ProductModule { }
