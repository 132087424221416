import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, NgZone } from '@angular/core';
import { ApiService } from 'src/app/services/api-service/api.service';
import { Observable } from 'rxjs';

@Component({
  selector: "mae-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {
  @ViewChild("recaptcha") recaptchaElement: ElementRef;

  isValid: boolean = false;
  errorMessage: string;
  informativaValida: boolean = false;
  check: boolean = false;
  captchaToken: string;
  form = [
    {
      name: "name",
      placeholder: "form.name",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-6",
      required: true,
      type: "text"
    },
    {
      name: "surname",
      placeholder: "form.surname",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-6",
      required: true,
      type: "text"
    },
    {
      name: "business",
      placeholder: "form.company",
      status: "",
      errorMessage: "",
      value: "",
      size: "",
      required: false,
      type: "text"
    },
    {
      name: "telephone",
      placeholder: "form.phone",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-6 large-4",
      required: true,
      type: "text"
    },
    {
      name: "cellular",
      placeholder: "form.cellular",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-6 large-4",
      required: false,
      type: "text"
    },
    {
      name: "email",
      placeholder: "form.email",
      status: "",
      errorMessage: "",
      value: "",
      size: "large-4",
      required: true,
      type: "text"
    },
    {
      name: "message",
      placeholder: "form.note",
      status: "",
      errorMessage: "",
      value: "",
      size: "",
      required: true,
      type: "textarea"
    }
  ];

  emailSent = false;
  preventDoubleSend = false

  constructor(private apiService: ApiService, private ngZone: NgZone) {}

  closeCreate() {
    this.emailSent = false;
  }

  addRecaptchaScript() {
    window["grecaptchaCallback"] = () => {
      this.renderReCaptcha();
    };

    (function(d, s, id, obj) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "recaptcha-jssdk", this);
  }

  renderReCaptcha() {
    this.ngZone.run(() => {
      window["grecaptcha"].render(this.recaptchaElement.nativeElement, {
        sitekey: "6Lc2QrsUAAAAAGjZDEuc79HXuDbjrYKhrHs_kfd5",
        callback: response => {
          this.captchaToken = response;
        }
      });
    });
  }

  onSubmit2() {
    const values = this.form
      .map(x => {
        return x.value ? { [x.name]: x.value } : undefined;
      })
      .filter(x => !!x);
    const data = Object.assign.apply({}, values);
  }
  onSubmit() {
    this.form.map(x => {
      this.fieldBlur(x.name);
    });
    const invalidObj = this.form.find(x => {
      return x.status === "invalid";
    });
    if (invalidObj || !this.informativaValida || this.captchaToken === "") {
      // invalid
      console.log("Form invalido");
      return;
    }
    // valid
    console.log("Form valido");
    const values = this.form
      .map(x => {
        return x.value ? { [x.name]: x.value } : undefined;
      })
      .filter(x => !!x);
    const data = Object.assign.apply({}, values);

    data.token = this.captchaToken;
    console.log("form data to send: ", data);
    console.log("token captcha:", this.captchaToken);
    this.sendForm(data);
    return;
  }

  sendForm(data: object): Observable<any> {
    return this.apiService.post("/contact", data).subscribe(
      () => {
        this.emailSent = true;
        this.preventDoubleSend = true
        console.log("Successfull!!!");
      },
      error => {
        if (error) {
          alert(
            "An error has occurred with statusNum: [ " + error.status + " ]"
          );
        }
        console.log("error.status: ", error.status, error);
      }
    );
  }

  fieldBlur(name) {
    let i = this.form.findIndex(x => {
      return x.name === name;
    });
    const item = this.form[i];
    if (!item.required) {
      return;
    }
    let validEmail = true;
    if (item.name === "email") {
      validEmail = this.validateEmail(this.form[i]);
    }
    if (!item.value || !item.value.trim() || !validEmail) {
      item.status = "invalid";
      item.errorMessage = `${item.placeholder}`;
      if (item.name === "email") {
        item.errorMessage = `${item.placeholder}`;
      }
      this.errorMessage = item.errorMessage
      console.log(this.errorMessage);
      this.isValid = false;
    } else {
      item.status = "valid";
      this.isValid = true;
    }
  }

  showErrorMessage(item) {
    if (!this.form[item].errorMessage) {
      return false;
    } else {
      return true;
    }
  }

  onFocus(name) {
    let i = this.form.findIndex(x => {
      return x.name === name;
    });
    let item = this.form[i];
    item.status = "";
    item.errorMessage = "";
  }

  validateEmail(formEmail) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(formEmail.value).toLowerCase());
    if (valid) {
      formEmail.status = "valid";
    } else {
      formEmail.status = "invalid";
    }
    return valid;
  }

  ngOnInit() {
    this.addRecaptchaScript();
  }
}
