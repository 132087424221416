import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'mae-html-text',
  templateUrl: './html-text.component.html',
  styleUrls: ['./html-text.component.scss']
})
export class HtmlTextComponent implements OnInit {

  @Input() payload: any = {
    title: 'La M.A.E.',
    // tslint:disable-next-line: max-line-length
    text: '<p>Georesistivimetro digitale per <b>Sondaggio Elettrico Verticale</b>. La macchina si caratterizza per la massima risoluzione ed accuratezza nell’indagine geoelettrica e per la grande rapidità operativa. Una volta concluso il ciclo di misura i dati acquisiti possono essere immediatamente processati con i relativi software di elaborazione dati.</p>',
    linkText: 'Approfondisci',
    link: 'linkBello',
  };

  constructor(
    private imageService: ImageService
  ) { }

  ngOnInit() {
  }

  getPath(path: string) {
    return this.imageService.sanitizedMediaUrl(path);
  }

  static get constructorName() {
    return 'HtmlTextComponent';
  }

}
