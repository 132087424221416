import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { FormsModule } from '@angular/forms';
import { URadioModule } from '../../u-radio/u-radio.module';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    URadioModule,
    UIRouterModule,
    TranslateModule
  ],
  exports: [SearchComponent]
})
export class SearchModule { }
