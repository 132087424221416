import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';
import { UIRouterModule } from '@uirouter/angular';
import { UInputModule } from 'src/app/components/u-input/u-input.module';
import { FeedbackModule } from 'src/app/components/feedback/feedback.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [ ContactComponent ],
	imports: [
		CommonModule,
		TranslateModule,
		UInputModule,
		UIRouterModule.forChild({
			states: [
				{
					name: 'app.contact',
					url: '/contact',
					component: ContactComponent
				}
			]
		}),
		FeedbackModule
	]
})
export class ContactModule {}
