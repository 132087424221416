import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { DDebounce } from "src/app/decorators/debounce";
import { ProductService } from "src/app/services/product.service";
import { CartService } from "src/app/services/cart.service";
import { trigger, transition, style, animate } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "src/app/services/api-service/api.service";
import { Transition, StateService } from "@uirouter/core";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "mae-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [
    trigger("loadingTransition", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate(".25s", style({ opacity: 0 }))]),
    ]),
    trigger("fadeIn", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".2s ease-in", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".2s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  menuOpen: string = "";
  keyword: string = "";
  activeSection: string = "";
  searchingCode: string = "";
  searchResults: any = [];
  searching = false;
  activeCategory = null;
  searchingNav = false;

  @Input() menuSections: Array<any> = [];
  @Input() menuTaxonomies: Array<any> = [];

  searchedSection: string = "";

  selectedMethodology: string = "";
  selectedCategory: string = "";
  selectedTypology: string = "";
  selectedSection: string = "";
  selectQuery: any;

  matchQuery: string;

  private expandedMethodologies: Array<any> = [];
  private expandedCategories: Array<any> = [];

  count: number = null;
  productsFound: Array<any>;
  arrowKeyLocation = -1;
  typologies: any = [];
  timeout: any;
  searched = "";
  private mode: string = "none";

  cartItems: any = [];

  @ViewChildren("searchResults") results: QueryList<ElementRef>;
  currentLocale: any;

  mobileSearchTriggered = false;

  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private el: ElementRef,
    private translate: TranslateService,
    private apiService: ApiService,
    private searchService: SearchService,
    private transition: Transition,
    private state: StateService
  ) {}

  ngOnInit() {
    this.currentLocale = this.state.params.locale;
    for (const sector of this.menuTaxonomies) {
      for (const category of sector.categories.sections) {
        category.active = true;
        this.expandedCategories.push(category);
      }
      for (const methodology of sector.methodologies.sections) {
        methodology.active = true;
        this.expandedMethodologies.push(methodology);
      }
    }

    try {
      this.typologies = this.menuSections
        .find((s) => {
          return s.section === "products";
        })
        .sections.map((e) => {
          e.active = true;
          return e;
        });
    } catch (e) {
      this.typologies = [];
    }

    this.cartItems = this.cartService.get()
  }

  initFullSearch(text?) {
    clearTimeout(this.timeout);
    if (text) {
      this.timeout = setTimeout(() => {
        this.searched = text;
        this.searchService.index().subscribe((res) => {
          this.productsFound = res.products;
        });
      }, 600);
    } else {
      this.searched = "";
    }
  }

  @DDebounce(100)
  @HostListener("window:resize", ["$event"])
  navResize(event) {
    if (event.target.innerWidth <= 1023 && this.isProductSearch) {
      this.setMode("nav");
    }
  }

  onChangeLang(locale) {
    this.apiService.setLocale(locale);
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === "ArrowUp") {
      // su
      this.arrowKeyLocation--;
      if (this.arrowKeyLocation < 0) {
        this.arrowKeyLocation = this.results.length - 1;
      }
    } else if (event.keyCode === 40) {
      // giù
      this.arrowKeyLocation++;
      if (this.arrowKeyLocation > this.results.length - 1) {
        this.arrowKeyLocation = 0;
      }
    } else if (event.keyCode === 13 && this.arrowKeyLocation !== -1) {
      const row = this.results.find((_, i) => i === this.arrowKeyLocation);
      const a = row.nativeElement.querySelector("a");
      console.log(a.click());
      this.arrowKeyLocation = -1;
    }
  }

  get isNav(): boolean {
    return this.mode === "nav";
  }

  get isProductSearch(): boolean {
    return this.mode === "search";
  }

  get hasCart() {
    return this.cartService.exists;
  }

  setActiveCategory(cat) {
    if (this.mode === "nav") {
      this.activeCategory === cat
        ? (this.activeCategory = null)
        : (this.activeCategory = cat);
      return;
    }
    this.activeCategory = cat;
  }

  getSlug(slug: Array<string>): string {
    return `/${slug.join("/")}`;
  }

  openSection(section: string): void {
    this.activeSection === section
      ? (this.activeSection = "")
      : (this.activeSection = section);
  }

  isActive(section: string): boolean {
    return this.activeSection === section;
  }

  setMode(mode: "nav" | "search" | "none"): void {
    if (this.mode === mode || mode === "none") {
      this.mode = "none";
      document.getElementsByTagName("html")[0].classList.remove("scroll-block");
      return;
    }
    document.getElementsByTagName("html")[0].classList.add("scroll-block");
    if (mode === "search") {
      this.mode = "search";
      if (!this.activeCategory) {
        this.activeCategory = this.menuTaxonomies[0].slug;
      }
      return;
    }
    this.mode = mode;
  }

  cleanInput() {
    this.searchingCode = "";
  }

  searchText(): void {
    this.searching = true;
    this.searchResults = [];
    this.getSearchResults();
  }

  @DDebounce(400)
  getSearchResults() {
    this.productService.search(null, null, this.keyword).subscribe((res) => {
      this.searching = false;
      this.searchResults = res.filter((r: any) => r.typology);
    });
  }

  matchQuerySection(mainTax) {
    return JSON.stringify({
      sections: mainTax.section,
    });
  }

  search(type: string): void {
    const match: any = {};
    this.matchQuery = undefined;

    this.searchingNav = true;

    const hasChangedSection = () => this.searchedSection !== this.activeSection;

    if (this.selectedMethodology) {
      if (
        (hasChangedSection() && type === "methodology") ||
        !hasChangedSection()
      ) {
        match.methodologies = this.selectedMethodology;
      } else if (hasChangedSection() && type !== "methodology") {
        this.selectedMethodology = null;
      }
    }

    if (this.selectedCategory) {
      if (
        (hasChangedSection() && type === "category") ||
        !hasChangedSection()
      ) {
        match.category = this.selectedCategory;
      } else if (hasChangedSection() && type !== "category") {
        this.selectedCategory = null;
      }
    }

    if (this.selectedTypology) {
      match["typology._id"] = this.selectedTypology;
    }

    this.searchedSection = this.activeSection;

    this.matchQuery = JSON.stringify(match);
    this.productService.search(match).subscribe(
      (res) => {
        this.count = (res || []).length;
        this.productsFound = res;
        this.rebaseSectors();

        setTimeout(() => {
          this.searchingNav = false;
        }, 750);
      },
      (err) => {
        console.log(err);

        setTimeout(() => {
          this.searchingNav = false;
        }, 750);
      }
    );
  }

  private rebaseSectors() {
    this.disableAllSelection();
    this.setCategoriesSelection();
    this.setMethodologiesSelection();
    this.setTypologiesSelection();
  }

  private disableAllSelection() {
    for (const cat of this.expandedCategories) {
      cat.active = false;
    }
    for (const met of this.expandedMethodologies) {
      met.active = false;
    }
    for (const typ of this.typologies) {
      typ.active = false;
    }
  }

  private setCategoriesSelection() {
    for (const cat of this.expandedCategories) {
      if (
        !this.selectedCategory ||
        (this.selectedCategory && this.selectedCategory === cat._id) ||
        this.searchedSection !== cat.taxonomy
      ) {
        cat.active = true;
      }
      if (
        !this.selectedCategory &&
        this.searchedSection === cat.taxonomy &&
        !this.productsFound.find((p) => p.category === cat._id)
      ) {
        cat.active = false;
      }
    }
  }

  private setMethodologiesSelection() {
    for (const met of this.expandedMethodologies) {
      if (
        !this.selectedMethodology ||
        (this.selectedMethodology && this.selectedMethodology === met._id) ||
        this.searchedSection !== met.taxonomy
      ) {
        met.active = true;
      }
      if (
        !this.selectedMethodology &&
        this.searchedSection === met.taxonomy &&
        !this.productsFound.find((p) => p.methodologies.indexOf(met._id) >= 0)
      ) {
        met.active = false;
      }
    }
  }

  private setTypologiesSelection() {
    for (const typ of this.typologies) {
      if (
        !this.selectedTypology ||
        (this.selectedTypology && this.selectedTypology === typ._id)
      ) {
        typ.active = true;
      }
      if (
        !this.selectedTypology &&
        !this.productsFound.find(
          (p) => p.typology && p.typology._id === typ._id
        )
      ) {
        typ.active = false;
      }
    }
  }

  get counterCartItems() {
    this.cartItems = this.cartService.get();
    if (
      this.cartItems &&
      this.cartItems.products &&
      this.cartItems.products.length
    ) {
      return this.cartItems.products.length;
    } else {
      return;
    }
  }
}
