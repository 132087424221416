import { Component, OnInit, Input, AfterContentInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "mae-video-list",
  templateUrl: "./video-list.component.html",
  styleUrls: ["./video-list.component.scss"],
})
export class VideoListComponent implements OnInit {
  @Input() payload: any = {};

  iframe: SafeHtml = "";
  payloadSano: any = {
    videos: []
  };

  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.payloadSano.videos = this.payload.videos.map(v => {
      v.sano = this.getSantizeIframe(v.iframe);
      return v;
    })
  }

  getSantizeIframe(iframe: string){
    return this.sanitizer.bypassSecurityTrustHtml(iframe)
  }

  static get constructorName() {
    return "VideoListComponent";
  }
}
