export abstract class AttachmentManager {

  abstract payload: any;

  attachments: Array<any>;
  page: number = 1;

  constructor() {}

  get hasMoreAttachments() {
    return this.attachments && this.payload.attachments && this.attachments.length < this.payload.attachments.length;
  }

  more() {
    this.page += 1;
    this.attachments = this.attachments.concat(this.getPagedAttachments());
  }

  getPagedAttachments() {
    const elements = Object.assign([], this.payload.attachments).splice(
      (this.payload.limit || 6) * (this.page - 1),
      (this.payload.limit || 6)
    );
    return elements;
  }

}
