import { Component, OnInit, Input } from '@angular/core';
import { CartService } from './services/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { Transition } from '@uirouter/core';

@Component({
	selector: 'mae-starter',
	templateUrl: './starter.component.html'
})
export class StarterComponent implements OnInit {
	@Input() menu: { sections: Array<any>; taxonomies: Array<any> };

	menuSections: Array<any> = [];
	menuTaxonomies: Array<any> = [];

	constructor(private cartService: CartService, private translateService: TranslateService,
		private transition: Transition) {}

	ngOnInit() {
		this.menuSections = this.menu.sections;
		this.menuTaxonomies = this.menu.taxonomies;
		if (!this.cartService.exists) {
			this.cartService.generate();
		} 
		this.translateService.use(this.transition.params().locale);
	}
}
