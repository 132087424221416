import { Pipe, PipeTransform } from "@angular/core";
import { config } from "src/environments/environment";

@Pipe({
  name: "resource",
})
export class ResourcePipe implements PipeTransform {
  transform(path: string): string {
    let url = `${config.api.protocol}://${config.api.domain}`;
    if (
      !config.api.port ||
      (config.api.port !== 80 && config.api.port !== 443)
    ) {
      url += `:${config.api.port}`;
    }
    return `${url}/${path}`;
  }
}
