import { Component, OnInit, Input } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'mae-magazine-index',
  templateUrl: './magazine-index.component.html',
  styleUrls: ['./magazine-index.component.scss']
})
export class MagazineIndexComponent implements OnInit {

  @Input() payload: any = {
    title: 'News',
    news: [
      {
        title: 'Corso Centro Studi Consiglio Nazionale Geologi',
        // tslint:disable-next-line: max-line-length
        short_abstract: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.',
        image: {
          file: {
            path: 'http://placehold.it/250'
          }
        },
      },
      {
        title: 'Rassegna delle tecnologie e aziende leader in settori',
        // tslint:disable-next-line: max-line-length
        short_abstract: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.',
        image: {
          file: {
            path: 'http://placehold.it/250'
          }
        },
      },
      {
        title: 'Con la MAE contro i terremoti',
        // tslint:disable-next-line: max-line-length
        short_abstract: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magn.',
        image: {
          file: {
            path: 'http://placehold.it/250'
          }
        },
      }
    ]
  };

  constructor(
    private pageService: PageService,
    private imageService: ImageService
  ) { }

  ngOnInit() {

    if (this.payload.selectedSection && this.payload.selectedSubSection && this.payload.selectedPages) {
      // TODO
    } else if (this.payload.selectedSection && this.payload.selectedSubSection) {
      this.pageService.index({
        params: {
          sub_pages_of: this.payload.selectedSubSection,
          limit: this.payload.limit || 3,
          page: 1
        }
      }).subscribe(res => {
        this.payload.news = res.map(p => {
          return {
            title: p.title,
            short_abstract: p.abstract,
            image: p.mainImage,
            slug: p.slug
          };
        });
      }, err => {
        console.log(err);
      });
    } else if (this.payload.selectedSection) {

    }

  }

  computeSlug(slug) {
    try {
      return '/' + slug.join('/');
    } catch (e) {
      return '';
    }
  }

  getPath(path) {
    return this.imageService.sanitizedBackgroundUrl(path);
  }

  static get constructorName() {
    return 'MagazineIndexComponent';
  }

}
