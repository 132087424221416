import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';
import { Transition } from '@uirouter/angular'
import { AttachmentManager } from '../attachment-manager';

@Component({
  selector: 'mae-attachment-index-list',
  templateUrl: './attachment-index-list.component.html',
  styleUrls: ['./attachment-index-list.component.scss']
})
export class AttachmentsIndexListComponent extends AttachmentManager implements OnInit {
  [x: string]: any;

  @Input() payload: any = {};
  @Input() title: String;

  constructor(
    private imageService: ImageService,
    public trans: Transition
  ) {
    super();
  }

  ngOnInit() {
  }

  getUrl(path: string): string {
    return this.imageService.mediaUrl(path);
  }

  static get constructorName() {
    return 'AttachmentsIndexListComponent';
  }

}
