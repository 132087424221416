import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { PageNotFoundComponent } from './page-not-found.component';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [
		BrowserModule,
		CommonModule,
		TranslateModule,

		UIRouterModule.forChild({
			states: [
				{
					name: 'app.404',
					url: '/page-not-found',
					component: PageNotFoundComponent
				}
			]
		})
	],
	declarations: [ PageNotFoundComponent ]
})
export class PageNotFoundModule {}
