import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'mae-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  menuTaxonomies: Array<any> = [];
  activeSection = '';
  searchedSection = '';

  selectedMethodology = '';
  selectedCategory = '';
  selectedTypology = '';

  matchQuery: string;
  selectQuery: string;

  private expandedMethodologies = [];
  private expandedCategories = [];

  count: number = null;
  productsFound: Array<any>;

  typologies: any = [];

  constructor(
    private menuService: MenuService,
    private productService: ProductService
  ) { }

  ngOnInit() {

    let subscriber;
    if (this.menuService.hasMenu) {
      subscriber = this.menuService.getMenu();
    } else {
      subscriber = this.menuService.onGetMenu;
    }

    subscriber.subscribe(menu => {

      this.menuTaxonomies = menu.mainTaxonomies;
      try {
        this.typologies = menu.mainSection.find(s => {
          return s.section === 'products';
        }).sections.map(e => {
          e.active = true;
          return e;
        });
      } catch (e) {
        this.typologies = [];
      }

      this.rebaseSectors({
        forceActiveTo: true
      });
      console.log(this.expandedCategories, this.expandedMethodologies);
    }, err => {
      console.log(err);
    });

  }

  openSection = (section) => {
    this.rebaseSectors({
      forceActiveTo: true,
      avoidSection: this.searchedSection
    });
    this.activeSection === section ? this.activeSection = '' : this.activeSection = section;
  }

  isActive = (section) => {
    return this.activeSection === section;
  }

  search(type: string) {

    const match: any = {};
    this.matchQuery = undefined;

    const hasChangedSection = () => this.searchedSection !== this.activeSection;

    if (this.selectedMethodology) {
      if (hasChangedSection() && type === 'methodology' || !hasChangedSection()) {
        match.methodologies = this.selectedMethodology;
      } else if (hasChangedSection() && type !== 'methodology') {
        this.selectedMethodology = null;
      }
    }
    if (this.selectedCategory) {
      if (hasChangedSection() && type === 'category' || !hasChangedSection()) {
        match.category = this.selectedCategory;
      } else if (hasChangedSection() && type !== 'category') {
        this.selectedCategory = null;
      }
    }
    if (this.selectedTypology) {
      match['typology._id'] = this.selectedTypology;
    }

    this.matchQuery = JSON.stringify(match);
    this.searchedSection = this.activeSection;

    this.productService.search(match).subscribe(res => {
      this.count = (res || []).length;
      this.productsFound = res;
      this.rebaseSectors();
    }, err => {
      console.log(err);
    });

  }

  rebaseSectors(options?: {
    forceActiveTo?: boolean,
    avoidSection?: string
  }) {

    if (options && options.forceActiveTo) {

      for (const sector of this.menuTaxonomies) {
        if (options && options.avoidSection && sector.section === options.avoidSection) {
          continue;
        }
        for (const category of sector.categories.sections) {
          category.active = options.forceActiveTo;
          this.expandedCategories.push(category);
        }
        for (const methodology of sector.methodologies.sections) {
          methodology.active = options.forceActiveTo;
          this.expandedMethodologies.push(methodology);
        }
      }

    } else {

      for (const cat of this.expandedCategories) {
        cat.active = false;
      }
      for (const met of this.expandedMethodologies) {
        met.active = false;
      }
      for (const typ of this.typologies) {
        typ.active = false;
      }

      for (const product of this.productsFound) {
        this.getCategory(product.category).active = true;
        for (const met of product.methodologies) {
          this.getMethodology(met).active = true;
        }
        this.getTypology(product.typology._id).active = true;
      }

    }

  }

  private getCategory(id: string): any {
    return this.expandedCategories[this.expandedCategories.findIndex(c => c._id === id)];
  }

  private getMethodology(id: string): any {
    return this.expandedMethodologies[this.expandedMethodologies.findIndex(m => m._id === id)];
  }

  private getTypology(id: string): any {
    return this.typologies[this.typologies.findIndex(t => t._id === id)];
  }

}
