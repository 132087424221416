import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api-service/api.service';

export interface TaxonomyShowInterface {
  taxonomy: string;
  category: string;
  slug: string;
}

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {

  constructor(
    private apiService: ApiService
  ) { }

  show(params: TaxonomyShowInterface): Observable<any> {

    let { taxonomy } = params;
    const { slug } = params;
    let { category } = params;
    if (category === 'categories' || category === 'categorie') {
      category = 'categories';
    } else {
      category = 'methodologies';
    }

    if (taxonomy === 'sismica') {
      taxonomy = 'seismic';
    } else if (taxonomy === 'geoelettrica') {
      taxonomy = 'geoelectric';
    } else if (taxonomy === 'monitoraggio') {
      taxonomy = 'monitoring';
    }

    return this.apiService.get(`/taxonomies/${taxonomy}/${category}/${slug}`);
  }

}
