import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CartService, CartElementInterface } from 'src/app/services/cart.service';
import { NotificationService, NotificationInterface } from 'src/app/services/notification.service';

@Component({
  selector: 'mae-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent implements OnInit {

  @Input() product: any;
  elements: Array<any>;
  active = null;

  constructor(
    public cartService: CartService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    if (this.product.isKit) {
      this.elements = this.product.kit;
    } else {
      const subProduct = this.product.subProducts.find(sp => sp._id === this.product.defaultSubProduct);
      this.elements = [{
        qty: 1,
        subProduct,
        product: this.product
      }];
    }
    this.setDefaultSelection();
  }

  closeAddToCart($event) {
    $event.preventDefault();
    this.cartService.close();
  }

  getSelected(cartElement): any {
    let selected = cartElement.product.subProducts.find(sp => {
      return sp.selected;
    });
    if (!selected) {
      selected = cartElement.product.subProducts.find(sp => {
        return sp.isDefault;
      });
    }
    return selected || null;
  }

  isSelected(subProduct): boolean {
    return subProduct.selected || subProduct.isDefault;
  }

  setSelected(cartElement, subProduct): void {
    Object.assign(
      cartElement.product.subProducts,
      cartElement.product.subProducts.map(sp => Object.assign(sp, { selected: false })),
      cartElement.product.subProducts.map(sp => Object.assign(sp, subProduct._id === sp._id ? { selected: true } : { selected: false }))
    );
  }

  addToCart($event) {
    this.cartService.add(this.toCartProduct(this.elements));
    this.notificationService.add({
      title: 'Aggiunto al carrello!',
      text: 'I prodotti selezionati sono stati aggiunti al carrello',
      status: 'primary'
    });
    this.closeAddToCart($event);
  }

  private toCartProduct(elements): any {
    return {
      product: this.product,
      subProducts: elements.map(element => {
        return {
          // todo: remove all unwanted attribs from product
          // product: { _id: element.product._id .. ecc ecc }
          product: element.product._id,
          qty: element.qty,
          subProducts: element.product.subProducts.map(sp => ({
            _id: sp._id,
            selected: sp.selected,
            name: sp.name,
            image: sp.mainImage && sp.mainImage.file && sp.mainImage.file.path ? sp.mainImage.file.path : null,
          })).filter(sp => sp.selected)
        };
      })
    };


  }

  private setDefaultSelection() {

    for (const cartElement of this.elements) {

      let id;
      if (!cartElement.subProduct) {
        id = cartElement.product.subProducts[0]._id;
      } else {
        id = cartElement.subProduct._id;
      }

      for (const subProducts of cartElement.product.subProducts) {
        subProducts.isDefault = subProducts._id === id;
        subProducts.selected = subProducts._id === id;
      }

    }

  }

}
