import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { URadioComponent } from './u-radio.component';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';

@NgModule({
  declarations: [URadioComponent],
  imports: [
    CommonModule,
    FormsModule,
    UIRouterModule
  ],
  exports: [URadioComponent]
})
export class URadioModule { }
