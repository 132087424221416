import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'mae-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss']
})
export class HighlightComponent implements OnInit {

  @Input() payload: any;

  constructor(
    private imageService: ImageService
  ) { }

  ngOnInit() {
  }

  getPath(path: string) {
    return this.imageService.sanitizedBackgroundUrl(path);
  }

  static get constructorName() {
    return 'HighlightComponent';
  }

}
