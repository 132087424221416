import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'mae-products-index',
  templateUrl: './products-index.component.html',
  styleUrls: ['./products-index.component.scss']
})
export class ProductsIndexComponent implements OnInit {

  @Input() payload: any = {};
  @Input() hasSpecifics: boolean = false

  component: any;
  page: number = 1;
  limit: number;

  constructor(
    private productService: ProductService,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    
    const match: any = {};
    const select: string = 'code abstract tags name slug typology mainImage specifications gallery';

    if (this.payload && this.payload.products) {
      this.initializeComponentWith(this.payload.products);
      return true;

    } else if (this.payload && !this.payload.products) {

      if (this.hasChoosedProducts) {
        match.code = { $in: this.payload.selectedProducts };
      } else if (this.hasMatchSearch) {
        Object.assign(match, this.payload.match);
      }

      this.productService.search(match, select).subscribe(products => {
        this.initializeComponentWith(products);
      }, err => {
        console.log(err);
      });

    } else {
      this.payload = {};
    }

    if (!this.payload.title) {
      this.payload.title = '';
    }
    if (!this.payload.limit) {
      this.payload.limit = 10;
    }

    this.productService.search(match, select).subscribe(products => {
      this.initializeComponentWith(products);
    }, err => {
      console.log(err);
    });

  }

  static get constructorName() {
    return 'ProductsIndexComponent';
  }

  private get hasChoosedProducts(): boolean {
    return !!this.payload.selectedProducts && !!this.payload.selectedProducts.length;
  }

  private get hasMatchSearch(): boolean {
    return !!this.payload.match;
  }

  private initializeComponentWith(products) {
    Object.assign(this.payload, { products: products || [] });
    this.generateProductsRoutes();
    this.chooseLayout();
  }

  private chooseLayout() {

    const payload = this.payload;

    switch (this.payload.layout) {
      case 'grid':
        this.component = { name: 'ProductsIndexGridComponent', payload };
        break;
      case 'slider':
        this.component = { name: 'ProductsIndexSliderComponent', payload };
        break;
      case 'table':
        this.component = { name: 'ProductsIndexTableComponent', payload };
        break;
      default:
        this.component = { name: 'ProductsIndexListComponent', payload };
        break;
    }

  }

  private generateProductsRoutes() {

    const productsSection = this.menuService.getSection('products');
    const baseProductsSlug = productsSection.slug;

    for (const product of this.payload.products) {
      if (!product.slug) {
        product.route = {};
        continue;
      }
      product.route = {
        state: 'app.product',
        params: {
          section: baseProductsSlug,
          category: product.typology ? product.typology.page.slug : '',
          slug: product.slug
        }
      };
    }

  }

}
