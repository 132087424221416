import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'mae-expanding-blocks',
  templateUrl: './expanding-blocks.component.html',
  styleUrls: ['./expanding-blocks.component.scss']
})
export class ExpandingBlocksComponent implements OnInit {

  @Input() payload: any = {};

  activeBlock: {};

  constructor(
    private imageService: ImageService
  ) { }

  ngOnInit() {
  }

  static get constructorName() {
    return 'ExpandingBlocksComponent';
  }

  getPath(path: string) {
    return this.imageService.sanitizedMediaUrl(path);
  }

}
