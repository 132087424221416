import { NgModule } from '@angular/core';
// TODO Do we relly need RouterModule?
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { UIRouterModule, Transition } from '@uirouter/angular';

import { StarterComponent } from './starter.component';

import { I18nService } from './services/i18n.service';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HomepageModule } from './ui/homepage/homepage.module';
import { PageNotFoundModule } from './ui/page-not-found/page-not-found.module';
import { CartModule } from './ui/cart/cart.module';
import { PageModule } from './ui/page/page.module';
import { HeaderModule } from './components/layout/header/header.module';
import { FooterModule } from './components/layout/footer/footer.module';
import { of } from 'rxjs';
import { ProductModule } from './ui/product/product.module';
import { ApiService } from './services/api-service/api.service';
import { MenuService } from './services/menu.service';
import { NotificationsModule } from './components/layout/notifications/notifications.module';
import { ContactModule } from './ui/contact/contact.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { config } from '../environments/environment';

export function localeResolve(apiService: ApiService, transition: Transition) {
	apiService.setLocale(transition.params().locale);
	localStorage.locale = transition.params().locale;
	return of(true).toPromise();
}

export function menuResolve(menuService: MenuService) {
	return menuService.get().toPromise();
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

let initialLang = "";
if (localStorage && localStorage.locale) {
	initialLang = localStorage.maeLanguageSettings;
} else {
	initialLang = navigator.language.split('-').shift().toLowerCase();
}
if (!config.i18n.availableLocales.includes(initialLang)) {
	initialLang = config.i18n.defaultLocale;
}

@NgModule({
	imports: [
		HttpClientModule,
		BrowserModule,
		RouterModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ HttpClient ]
			}
		}),

		HeaderModule,
		FooterModule,
		NotificationsModule,

		UIRouterModule.forRoot({
			initial: `/${initialLang}`,
			states: [
				{
					name: 'app',
					url: '/:locale',
					component: StarterComponent,
					resolve: [
						{
							deps: [ ApiService, Transition ],
							token: 'locale',
							resolveFn: localeResolve
						},
						{
							deps: [ MenuService ],
							token: 'menu',
							resolveFn: menuResolve
						}
					],
					redirectTo: 'app.homepage'
				}
			]
			// otherwise: '/page-not-found'
		}),

		/**
     * ProductModule deve esser importati prima di PageModule altrimenti non vengono
     * risolte correttamente le rotte
     */
		ProductModule,
		HomepageModule,
		PageModule,
		CartModule,
		PageNotFoundModule,
		ContactModule
	],
	providers: [ ApiService, I18nService ],
	exports: [],
	declarations: [ AppComponent, StarterComponent],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
