import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './page.component';
import { PartialModule } from 'src/app/components/partials/partial.module';
import { UIRouterModule, Transition, UIRouter, RejectType } from '@uirouter/angular';
import { PageService } from 'src/app/services/page.service';
import { TaxonomyService, TaxonomyShowInterface } from 'src/app/services/taxonomy.service';
import { SearchModule } from 'src/app/components/layout/search/search.module';
import { ProductService } from 'src/app/services/product.service';
import { TranslateModule } from '@ngx-translate/core';

export function taxonomyResolve(taxonomyService: TaxonomyService, transition: Transition) {
	return taxonomyService.show(transition.params() as TaxonomyShowInterface).toPromise();
}

export function pageResolve(pageService: PageService, transition: Transition) {
	const params = transition.params();
	return pageService.show(`${params.slug}`).toPromise();
}

export function hasProductIndex(pageService: PageService, transition: Transition) {
	const params = transition.params();
	return pageService.show(`${params.slug}`).toPromise();
}

export function searchProductResolve(productService: ProductService, transition: Transition) {
	const params = transition.params();
	return productService.search(params.match || '', params.select || '').toPromise();
}

export function authConfig(router: UIRouter, injector: Injector) {
	router.transitionService.onError(
		{
			to: 'app.*'
		},
		(trans) => {
			const rejection = trans.error();
			if (rejection && rejection.detail && rejection.detail.status === 404) {
				trans.router.stateService.go('app.404', {
					locale: 'it'
				});
			} else {
				console.log('Transition error', rejection);
				return true;
			}
		}
	);
}

@NgModule({
	declarations: [PageComponent],
	imports: [
		CommonModule,
		PartialModule,
    SearchModule,
    TranslateModule,

		UIRouterModule.forChild({
			states: [
				{
					name: 'app.search',
					url: '/search?match&select',
					component: PageComponent,
					params: {
						match: { value: '' },
						select: { value: '' }
					},
					resolve: [
						{
							token: 'products',
							deps: [ProductService, Transition],
							resolveFn: searchProductResolve
						}
					]
				},
				{
					name: 'app.taxonomy',
					url: '/:taxonomy/{category: metodologie|categorie|methodologies|categories}/:slug',
					params: {
						hasProductIndex: {
							value: true
						},
						autoImport: true
					},
					component: PageComponent,
					resolve: [
						{
							token: 'page',
							deps: [TaxonomyService, Transition],
							resolveFn: taxonomyResolve
						}
					]
				},
				{
					name: 'app.page',
					url: '{slug: .*}',
					params: {
						slug: {
							value: '',
							raw: true
						}
					},
					component: PageComponent,
					resolve: [
						{
							token: 'page',
							deps: [PageService, Transition],
							resolveFn: pageResolve
						}
					]
				}
			],
			config: authConfig
		})
	],
	exports: [PageComponent]
})
export class PageModule { }
