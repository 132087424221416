import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { ApiService } from './api-service/api.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  mainSection: Array<any> = [];
  mainTaxonomies: Array<any> = [];

  onGetMenu: Subject<any> = new Subject<any>();

  constructor(
    private apiService: ApiService
  ) { }

  get(): Observable<any> {
    return this.apiService.get('/menu').pipe(
      tap((menu: {sections: Array<any>, taxonomies: Array<any>}) => {
        this.mainSection = menu.sections;
        this.mainTaxonomies = menu.taxonomies;
        this.onGetMenu.next({
          mainSection: this.mainSection,
          mainTaxonomies: this.mainTaxonomies
        });
      })
    );
  }

  get hasMenu() {
    return this.mainSection && this.mainTaxonomies;
  }

  getMenu(): Observable<{
    mainSection: any,
    mainTaxonomies: any
  }> {
    return of({
      mainSection: this.mainSection,
      mainTaxonomies: this.mainTaxonomies
    });
  }

  getSection(section: string): any {
    return this.mainSection.find(element => {
      return element.section === section;
    }) || null;
  }

  getTaxonomy(taxonomy: string): any {
    return this.mainTaxonomies.find(element => {
      return element.section === taxonomy;
    }) || null;
  }

}
