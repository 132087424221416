import { Component, OnInit } from '@angular/core';
import { NotificationService, NotificationInterface } from 'src/app/services/notification.service';

@Component({
  selector: 'mae-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifications: Array<NotificationInterface> = [];

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.notifications = this.notificationService.getNotifications;
  }

}
