import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit
} from "@angular/core";
import { ApiService } from "src/app/services/api-service/api.service";

@Component({
  selector: "mae-google-map",
  templateUrl: "./google-map.component.html",
  styleUrls: ["./google-map.component.scss"]
})
export class GoogleMapComponent implements OnInit, AfterViewInit {
  @Input() payload: any;
  // @ts-ignore
  @ViewChild("mapContainer") gmap: ElementRef;

  map: google.maps.Map;
  geocoder: any;
  allMarkers: google.maps.Marker[] = [];
  mapStyles: any[] = [
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 65
        },
        {
          visibility: "on"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 51
        },
        {
          hue: "#ff0000"
        },
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          visibility: "simplified"
        },
        {
          color: "#ffd900"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "simplified"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 30
        },
        {
          visibility: "simplified"
        },
        {
          color: "#ffe761"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 40
        },
        {
          visibility: "simplified"
        },
        {
          color: "#bfbfbf"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          saturation: -100
        },
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          lightness: -25
        },
        {
          saturation: -97
        },
        {
          color: "#616161"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [
        {
          visibility: "on"
        },
        {
          lightness: -25
        },
        {
          saturation: -100
        },
        {
          color: "#050505"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }
  ];

  showMarkerInfo = {
    status: false,
    message: null
  };

  constructor(private apiService: ApiService) {}

  closeCreate() {
    this.showMarkerInfo.status = false;
  }

  initializeMap(markers: google.maps.Marker[], mapOptions) {
    this.map = new google.maps.Map(this.gmap.nativeElement, mapOptions);
    markers.forEach((element: google.maps.Marker) => {
      google.maps.event.addListener(element, "click", () => {
        this.showMarkerInfo.status = true;
        // @ts-ignore
        this.showMarkerInfo.message = element.title ? element.title : 'No info provided'
      });
      element.setMap(this.map);
    });
  }

  getPosition(address: string): Promise<{ lat: number; lng: number }> {
    console.error("DO NOT USE");
    return new Promise((resolve, reject) => {
      this.geocoder = new google.maps.Geocoder();
      this.geocoder.geocode({ address }, (res, status) => {
        if (res.length) {
          resolve({
            lat: res[0].geometry.location.lat(),
            lng: res[0].geometry.location.lng()
          });
        } else {
          resolve({
            lat: undefined,
            lng: undefined
          });
        }
      });
    });
  }

  ngOnInit() {
    this.apiService.get("/map-data").subscribe(res => {
      res.forEach((element: any) => {
        if (element.lat && element.lng) {
          const coordinates = new google.maps.LatLng(element.lat, element.lng);
          const marker = new google.maps.Marker({
            position: coordinates,
            title: element.city,
            map: this.map
          });
          this.allMarkers.push(marker);
        }
        // else {
        //   this.getPosition(element.address1).then((res: any) => {
        //     if (res.lat && res.lng) {
        //       const coordinates = new google.maps.LatLng(res.lat, res.lng);
        //       const marker = new google.maps.Marker({
        //         position: coordinates,
        //         map: this.map
        //       });
        //       this.allMarkers.push(marker);
        //     }
        //   });
        // }
      });
      if (this.allMarkers.length > 0) {
        const coordinates = new google.maps.LatLng(res[0].lat, res[0].lng);
        const mapOptions: google.maps.MapOptions = {
          center: coordinates,
          zoom: 2,
          styles: this.mapStyles
        };
        this.initializeMap(this.allMarkers, mapOptions);
      }
    }, console.error);
  }

  ngAfterViewInit() {}

  static get constructorName() {
    return "GoogleMapComponent";
  }
}
