import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'mae-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit, AfterContentInit {

  @Input() payload: any = {};
  iframe: SafeHtml = '';

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.iframe = this.sanitizer.bypassSecurityTrustHtml(this.payload.iframe);
  }


  static get constructorName() {
    return 'IframeComponent';
  }

}
