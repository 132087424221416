import { BigHeroComponent } from './big-hero/big-hero.component';
import { ExpandingBlocksComponent } from './expanding-blocks/expanding-blocks.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HeroComponent } from './hero/hero.component';
import { HtmlTextComponent } from './html-text/html-text.component';
import { MagazineIndexComponent } from './magazine-index/magazine-index.component';
import { ResourcesIndexComponent } from './resources-index/resources-index.component';
import { TextComponent } from './text/text.component';
import { ProductsIndexComponent } from './products-index/products-index.component';
import { ProductsIndexSliderComponent } from './products-index/products-index-slider/products-index-slider.component';
import { ProductsIndexGridComponent } from './products-index/products-index-grid/products-index-grid.component';
import { ProductsIndexListComponent } from './products-index/products-index-list/products-index-list.component';
import { ProductsIndexTableComponent } from './products-index/products-index-table/products-index-table.component';
import { IframeComponent } from './iframe/iframe.component';
import { HighlightComponent } from './highlight/highlight.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { AttachmentsIndexListComponent } from './attachment-index/attachment-index-list/attachment-index-list.component';
import { AttachmentIndexComponent } from './attachment-index/attachment-index.component';
import { VideoListComponent } from './video-list/video-list.component';

export const entryComponents = [
         BigHeroComponent,
         ExpandingBlocksComponent,
         GalleryComponent,
         HeroComponent,
         HtmlTextComponent,
         MagazineIndexComponent,
         ProductsIndexComponent,
         ResourcesIndexComponent,
         TextComponent,
         ProductsIndexSliderComponent,
         ProductsIndexGridComponent,
         ProductsIndexListComponent,
         ProductsIndexTableComponent,
         IframeComponent,
         HighlightComponent,
         GoogleMapComponent,
         AttachmentsIndexListComponent,
         AttachmentIndexComponent,
         VideoListComponent
       ];
