// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

interface ApiConfig {
  protocol: string;
  domain: string;
  port: number;
  basePath: string;
  fullPath: string;
}

interface I18nConfig {
  defaultLocale: string;
  availableLocales: Array<string>;
}

interface Config {
  api: ApiConfig;
  i18n?: I18nConfig;
}

export let config: Config;

config = {
  api: {
    protocol: "https",
    domain: "api.mae-srl.it",
    port: 443,
    basePath: "/api/v1/website/{{locale}}",
    fullPath: null,
  },
  // api: {
  //   protocol: 'http',
  //   domain: 'localhost',
  //   port: 3000,
  //   basePath: '/api/v1/website/{{locale}}',
  //   fullPath: null
  // }
  // api: {
  //   protocol: 'https',
  //   domain: 'api.mae-srl.it',
  //   port: 80,
  //   basePath: '/api/v1/website/{{locale}}',
  //   fullPath: null
  // }
};

config.i18n = {
  defaultLocale: 'it',
  availableLocales: [ 'it']
};

const {
  protocol,
  domain,
  port,
  basePath
} = config.api;

const getBasePath = () => basePath[0] === '/' ? basePath : '/' + basePath;
if (port === 80 || port === 443) {
  config.api.fullPath = `${protocol}://${domain}${getBasePath()}`;
} else {
  config.api.fullPath = `${protocol}://${domain}:${port}${getBasePath()}`;
}
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
