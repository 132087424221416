import { Component, OnInit } from "@angular/core";
import { CartService } from "src/app/services/cart.service";
import {
  NotificationService,
  NotificationInterface,
} from "src/app/services/notification.service";
import { ImageService } from "src/app/services/image.service";
import { Transition } from "@uirouter/angular";
import { ApiService } from "src/app/services/api-service/api.service";
import { MenuService } from "src/app/services/menu.service";

@Component({
  selector: "mae-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
  page = null;
  cartContent: any = [];
  step = 1;

  /* New */
  isValid: boolean = false;
  errorMessage: string;
  informativaValida: boolean = false;
  orderSent = false;
  /* /New */

  /* New */
  newForm = [
    {
      name: "firstName",
      placeholder: "form.name",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-6",
      required: true,
      type: "text",
    },
    {
      name: "lastName",
      placeholder: "form.surname",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-6",
      required: true,
      type: "text",
    },
    {
      name: "company",
      placeholder: "form.company",
      status: "",
      errorMessage: "",
      value: "",
      size: "",
      required: true,
      type: "text",
    },
    {
      name: "address",
      placeholder: "form.address",
      status: "",
      errorMessage: "",
      value: "",
      size: "large-6",
      required: true,
      type: "text",
    },
    {
      name: "citty",
      placeholder: "form.city",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-8 large-4",
      required: true,
      type: "text",
    },
    {
      name: "code",
      placeholder: "form.code",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-4 large-2",
      required: true,
      type: "text",
    },
    {
      name: "phone",
      placeholder: "form.phone",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-6",
      required: true,
      type: "text",
    },
    {
      name: "cellPhone",
      placeholder: "form.cellular",
      status: "",
      errorMessage: "",
      value: "",
      size: "medium-6",
      required: false,
      type: "text",
    },
    {
      name: "email",
      placeholder: "form.email",
      status: "",
      errorMessage: "",
      value: "",
      size: "",
      required: true,
      type: "text",
    },
    {
      name: "notes",
      placeholder: "form.note",
      status: "",
      errorMessage: "",
      value: "",
      size: "",
      required: false,
      type: "textarea",
    },
  ];
  /* /New */

  constructor(
    private cartService: CartService,
    private notificationService: NotificationService,
    public trans: Transition,
    private menuService: MenuService
  ) {}

  /* New */
  // Missing onSubmit() & sendForm() & onChecked()
  // & disabled-button if informativa/wrong input fields

  fieldBlur(name) {
    let i = this.newForm.findIndex((x) => {
      return x.name === name;
    });
    const item = this.newForm[i];
    if (!item.required) {
      return;
    }
    let validEmail = true;
    if (item.name === "email") {
      validEmail = this.validateEmail(this.newForm[i]);
    }
    if (!item.value || !item.value.trim() || !validEmail) {
      item.status = "invalid";
      item.errorMessage = `${item.placeholder}`;
      if (item.name === "email") {
        item.errorMessage = `${item.placeholder}`;
      }
      this.errorMessage = item.errorMessage;
      console.log(this.errorMessage);
      this.isValid = false;
    } else {
      item.status = "valid";
      this.isValid = true;
    }
  }

  onFocus(name) {
    let i = this.newForm.findIndex((x) => {
      return x.name === name;
    });
    let item = this.newForm[i];
    item.status = "";
    item.errorMessage = "";
  }

  validateEmail(formEmail) {
    // tslint:disable-next-line: max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = re.test(String(formEmail.value).toLowerCase());
    if (valid) {
      formEmail.status = "valid";
    } else {
      formEmail.status = "invalid";
    }
    return valid;
  }
  /* /New */

  ngOnInit() {
    this.getCart();
    console.log(this.cartContent);
  }

  getCart() {
    const cart = this.cartService.get();
    this.cartContent = cart.products;
    const productsSection = this.menuService.getSection("products");
    const baseProductsSlug = productsSection.slug;
    this.cartContent.forEach((element) => {
      element.product.route = {
        state: "app.product",
        params: {
          section: baseProductsSlug,
          category: element.product.typology
            ? element.product.typology.page.slug
            : "",
          slug: element.product.slug,
        },
      };
    });
  }

  remove(i) {
    const remove = confirm("Vuoi rimuovere questo prodotto dal carrello?");
    if (!remove) {
      return;
    }
    this.cartService.remove(i);
    this.notificationService.add({
      title: "Prodotto rimosso dal carrello!",
      text: "Il prodotto è stato rimosso con successo da carrello",
      status: "primary",
    });
    this.getCart();
  }
  addMore(element) {
    element.qty += 1;
    console.log(this.cartContent);
    const cart = this.cartService.get();
    localStorage.setItem(
      "cart",
      JSON.stringify({ ...cart, products: this.cartContent })
    );
  }
  addLess(element) {
    element.qty -= 1;
    if (element.qty < 1) {
      return (element.qty = 0);
    }
    console.log(this.cartContent);
    const cart = this.cartService.get();
    localStorage.setItem(
      "cart",
      JSON.stringify({ ...cart, products: this.cartContent })
    );
  }
  // getPath(path) {
  // 	return this.imageService.sanitizedMediaUrl(path);
  // }

  isKit(element): boolean {
    const prdId = element.product._id;
    if (
      element.subProducts.length === 1 &&
      element.subProducts[0].product === prdId
    ) {
      return false;
    }
    return true;
  }

  cutAbstract(abstract: string): string {
    if (abstract.length > 75) {
      return abstract.slice(0, 75) + "...";
    }
    return abstract;
  }

  onSubmit() {
    this.newForm.map((x) => {
      this.fieldBlur(x.name);
    });
    const invalidObj = this.newForm.find((x) => {
      return x.status === "invalid";
    });
    if (invalidObj || !this.informativaValida) {
      // invalid
      console.log("Form invalido");
      return;
    }
    // valid
    console.log("Form valido");
    const values = this.newForm
      .map((x) => {
        return x.value ? { [x.name]: x.value } : undefined;
      })
      .filter((x) => !!x);
    const data = Object.assign.apply({}, values);
    const cartContent = this.cartService.get();

    // formattiamo i prodotti
    // lasciando solo le proprietà utili all'invio mail
    cartContent.products.forEach((element) => {
      let el;
      if (element && element.product && !element.product.isKit) {
        el = {
          name: element.subProducts[0].subProducts[0].name,
          isKit: element.product.isKit,
        };
        element.product = el;
      }

      if (element && element.product && element.product.isKit) {
        el = {
          name: element.product.name,
          isKit: element.product.isKit,
        };
        element.product = el;
      }
    });
    // console.log("formattato", cartContent);
    data.confirmation = true;
    data.cart = cartContent.products;
    data.token = cartContent.token;
    // console.log(data.cart);
    console.log("form data to send: ", data);
    this.checkout(data);
    return;
  }

  checkout(data) {
    this.cartService.checkout(data).subscribe(
      (res) => {
        // console.log(res);
        this.orderSent = true;
        localStorage.clear();
      },
      (err) => {
        this.orderSent = false;
        console.error(err);
      }
    );
  }

  closeCreate() {
    this.orderSent = true;
    window.location.href =
      this.trans.params().locale === "it" ? "/it/prodotti" : "/en/products";
  }

  get getProductsUrl() {
    return this.trans.params().locale === "it" ? "/prodotti" : "/products";
  }
}
