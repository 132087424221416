import { Injectable } from '@angular/core'
import { Title, Meta, DomSanitizer } from '@angular/platform-browser'
// import { ResourcePipe } from '../pipes/resource.pipe'
import { TransitionService } from '@uirouter/core'
import { I18nService } from './i18n.service'
import { ImageService } from './image.service';

@Injectable({
  providedIn: "root",
})
export class MetaService {
  private defaultTitle = "M.A.E srl";
  private prefix = "M.A.E - ";
  private defaultDescription =
    "Da sempre MAE si caratterizza per la progettazione e la produzione di sistemi su misura, studiati sulla base delle esigenze del cliente utilizzando le tecnologie più avanzate attualmente disponibili.";
  private defaultKeywords = "";
  private defaultType = "website";

  constructor(
    private title: Title,
    private meta: Meta,
    // private resourcePipe: ResourcePipe,
    private transitionService: TransitionService,
    private i18nService: I18nService,
    private imageService: ImageService
  ) {
    transitionService.onSuccess({ to: "**" }, (trans) => {
      if (this.i18nService.getLocale(false) === "it") {
        this.meta.updateTag({
          property: "og:locale",
          content: "it_IT",
        });
        this.meta.updateTag({
          property: "og:locale:alternate",
          content: "en_US",
        });
      } else {
        this.meta.updateTag({
          property: "og:locale",
          content: "en_US",
        });
        this.meta.updateTag({
          property: "og:locale:alternate",
          content: "it_IT",
        });
      }
      // URL
    });
  }

  init() {
    this.initLocale();
    this.initUrl();
  }

  private initUrl() {
    this.meta.updateTag({
      property: "og:url",
      content: window.location.href,
    });
  }

  private initLocale() {
    this.meta.addTag({
      property: "og:locale",
      content: "it_IT",
    });
    this.meta.addTag({
      property: "og:locale:alternate",
      content: "en_US",
    });
  }

  getTitle() {
    return this.title.getTitle();
  }

  setMeta(meta) {
    const title = this.prefix + meta.title || this.defaultTitle;
    // TITLE
    this.title.setTitle(title);
    this.meta.updateTag({
      property: "og:title",
      content: title,
    });
    this.meta.updateTag({
      name: "twitter:title",
      content: title,
    });
    // KEYWORDS
    this.meta.updateTag({
      name: "keywords",
      content: meta.keywords || this.defaultKeywords,
    });
    // DESCRIPTION
    this.meta.updateTag({
      name: "description",
      content: meta.description || this.defaultDescription,
    });
    this.meta.updateTag({
      property: "og:description",
      content: meta.description || this.defaultDescription,
    });
    this.meta.updateTag({
      name: "twitter:description",
      content: meta.description || this.defaultDescription,
    });
    this.meta.updateTag({
      property: "og:type",
      content: meta.type || this.defaultType,
    });
    // IMAGE
    const image = this.getImagePath(meta.image);
    if (image) {
      this.meta.updateTag({
        property: "og:image",
        content: image,
      });
      this.meta.updateTag({
        name: "twitter:image",
        content: image,
      });
    } else {
      this.meta.removeTag('property="og:image"');
      this.meta.removeTag('name="twitter:image"');
    }
  }

  private getImagePath(image) {
    if (image) {
      let imagePath;
      if (typeof image === typeof "string") {
        imagePath = this.imageService.sanitizedMediaUrl(image);
      } else {
        imagePath = this.imageService.sanitizedMediaUrl(image.file.path);
      }
      if (
        imagePath &&
        imagePath.changingThisBreaksApplicationSecurity &&
        imagePath.changingThisBreaksApplicationSecurity.substr(0, 5) !==
          "http:" &&
        imagePath.changingThisBreaksApplicationSecurity.substr(0, 6) !==
          "https:"
      ) {
        return "https:" + imagePath.changingThisBreaksApplicationSecurity;
      } else {
        return imagePath.changingThisBreaksApplicationSecurity;
      }
    }
    return null;
  }
}
