import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'mae-u-input',
	templateUrl: './u-input.component.html',
	styleUrls: [ './u-input.component.scss' ]
})
export class UInputComponent {
	@Input() model: any;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() required: boolean = false;
	@Input() type: 'text' | 'textarea' | 'email' = 'text';
  @Input() errorMessage;

	@Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() blur: EventEmitter<any> = new EventEmitter<any>();
	@Output() focus: EventEmitter<any> = new EventEmitter<any>();
	@Output() validateEmail: EventEmitter<any> = new EventEmitter<any>();

  initialize = true;
  value: any;

	constructor() {}

	onChange() {
		this.modelChange.emit(this.model);
	}

	onBlur() {
		this.blur.emit(this.model);
	}
	onFocus() {
		this.focus.emit(this.model);
	}
	onValidateEmail() {
		this.validateEmail.emit(this.model);
	}

	get error() {
		return this.errorMessage.length > 0;
	}
}
