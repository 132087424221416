import { Injectable } from '@angular/core';

export interface NotificationInterface {
  id?: number;
  title: string;
  text: string;
  duration?: number;
  status?: 'primary' | 'warning' | 'danger';
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notifications: Array<NotificationInterface> = [];

  constructor() { }

  get getNotifications(): Array<NotificationInterface> {
    return this._notifications;
  }

  add(element: NotificationInterface) {
    if (!element.id) {
      element.id = Math.floor(Math.random() * 10000) + 1;
    }
    this._notifications.push(element);
    setTimeout(() => {
      const i = this._notifications.findIndex(x => {
        return x.id === element.id;
      });
      this._notifications.splice(i, 1);
    }, element.duration || 3500);
  }
}
