import { Component, AfterViewInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'mae-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent implements AfterViewInit {
	title = 'mae-website';

	constructor(private uiRouter: UIRouter, private translate: TranslateService) {
		translate.setDefaultLang('it');
	}

	ngAfterViewInit() {
		this.uiRouter.transitionService.onSuccess({}, function() {
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 1);
		});
	}
}
