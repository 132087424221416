import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api-service/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  searchedProducts: Array<any>;

  constructor(
    private apiService: ApiService
  ) { }

  show(slug: string, category?: string): Observable<any> {
    if (category) {
      return this.apiService.get(`/products/${category}/${slug}`);
    } else {
      return this.apiService.get(`/products/${slug}`);
    }
  }

  pdfUrl(slug: string, category?: string): string {
    if (category) {
      return `${this.apiService.getFullRequestBasePath()}/products/${category}/${slug}.pdf`;
    } else {
      return `${this.apiService.getFullRequestBasePath()}/products/${slug}.pdf`;
    }
  }

  index(options?) {
    return this.apiService.get(`/products`, options);
  }

  search(match?: any, select?, keyword?): Observable<any> {
    let params: any = {};
    if(select) {
      params.select = select;
    }
    if(keyword) {
      params.keyword = keyword;
    }
    if(match){
      if (typeof match === typeof '') {
        params.match = match;
      } else {
        params.match = JSON.stringify(match).replace(/---/g, '.');;
      }
    }
    return this.apiService
      .get('/search', {
        observe: "response",
        params
      })
      .pipe(
        map((res: any) => {
          return res.body;
        })
      );
  }
}
