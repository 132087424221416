import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import Swiper from 'swiper';
import { ImageService } from 'src/app/services/image.service';
import { ProductManager } from '../product-manager';

@Component({
  selector: 'mae-products-index-slider',
  templateUrl: './products-index-slider.component.html',
  styleUrls: [
    './products-index-slider.component.scss'
  ]
})
export class ProductsIndexSliderComponent extends ProductManager implements OnInit, AfterContentInit {

  @Input() payload: any = {};
  swiper: any = null;
  randomId = Math.floor(Math.random() * 500);

  constructor(
    private imageService: ImageService
  ) {
    super();
  }

  ngOnInit() {

    this.products = this.getPagedProducts();

  }

  ngAfterContentInit() {
    setTimeout(() => {
      if (this.products.length > 1) {
        this.swiper = new Swiper('#swiper-' + this.randomId, {
          loop: true,
          slidesPerView: 1,
          autoHeight: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
        });
      }
    });
  }

  getUrl(path: string): string {
    return this.imageService.mediaUrl(path);
  }

  static get constructorName() {
    return 'ProductsIndexSliderComponent';
  }

}
