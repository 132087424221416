import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentLoaderComponent } from './components-loader/component-loader/component-loader.component';
import { ComponentsLoaderComponent } from './components-loader/components-loader.component';
import { TextComponent } from './components/text/text.component';
import { MagazineIndexComponent } from './components/magazine-index/magazine-index.component';
import { HtmlTextComponent } from './components/html-text/html-text.component';
import { ExpandingBlocksComponent } from './components/expanding-blocks/expanding-blocks.component';
import { HeroComponent } from './components/hero/hero.component';
import { ProductsIndexComponent } from './components/products-index/products-index.component';
import { ProductsIndexSliderComponent } from './components/products-index/products-index-slider/products-index-slider.component';
import { ProductsIndexGridComponent } from './components/products-index/products-index-grid/products-index-grid.component';
import { ProductsIndexListComponent } from './components/products-index/products-index-list/products-index-list.component';
import { ResourcesIndexComponent } from './components/resources-index/resources-index.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { BigHeroComponent } from './components/big-hero/big-hero.component';
import { ProductHeroComponent } from './components/product-hero/product-hero.component';
import { UIRouterModule } from '@uirouter/angular';
import { IframeComponent } from './components/iframe/iframe.component';
import { ProductsIndexTableComponent } from './components/products-index/products-index-table/products-index-table.component';
import { HighlightComponent } from './components/highlight/highlight.component';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { AttachmentsIndexListComponent } from './components/attachment-index/attachment-index-list/attachment-index-list.component';
import { AttachmentIndexComponent } from './components/attachment-index/attachment-index.component';
import { FeedbackModule } from '../feedback/feedback.module';
import { VideoListComponent } from './components/video-list/video-list.component';
@NgModule({
  declarations: [
    ComponentsLoaderComponent,
    ComponentLoaderComponent,
    TextComponent,
    MagazineIndexComponent,
    HtmlTextComponent,
    ExpandingBlocksComponent,
    HeroComponent,
    ProductsIndexComponent,
    ProductsIndexSliderComponent,
    ProductsIndexGridComponent,
    ProductsIndexListComponent,
    ProductsIndexTableComponent,
    ResourcesIndexComponent,
    GalleryComponent,
    BigHeroComponent,
    ProductHeroComponent,
    IframeComponent,
    HighlightComponent,
    GoogleMapComponent,
    AttachmentsIndexListComponent,
    AttachmentIndexComponent,
    VideoListComponent

  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    UIRouterModule,
    TranslateModule,
    FeedbackModule
  ],
  exports: [
    ComponentsLoaderComponent,
    ComponentLoaderComponent,
    TextComponent,
    MagazineIndexComponent,
    HtmlTextComponent,
    ExpandingBlocksComponent,
    HeroComponent,
    ProductsIndexComponent,
    ResourcesIndexComponent,
    GalleryComponent,
    BigHeroComponent,
    ProductHeroComponent,
		IframeComponent,
    GoogleMapComponent,
    AttachmentsIndexListComponent,
    AttachmentIndexComponent,
    VideoListComponent
  ]
})
export class PartialModule {}
