import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { BehaviorSubject, forkJoin, Observable } from 'rxjs'
import { ApiService } from './api-service/api.service'
import { ProductService } from './product.service'
import { Restify } from './api-service/restify.class'
@Injectable({
  providedIn: 'root'
})
export class SearchService extends Restify {

  basePath = '/search?keyword='

  constructor(
    private productService: ProductService,
    protected apiService: ApiService
  ) {
    super(apiService)
  }

  search(text: string): Observable<any[]> {
    return forkJoin(
      this.productService.index('?$text[$search]=' + text + '&limit=6'),
    )
  }
}
