import { Component, OnInit, Input } from '@angular/core';
import { Transition } from '@uirouter/angular'

@Component({
  selector: 'mae-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() menuSections: Array<any> = [];
  @Input() menuTaxonomies: Array<any> = [];

  constructor(public trans: Transition) { }

  ngOnInit() {
  }

  getSlug(slug: Array<string>): string {
    return `/${slug.join('/')}`;
  }


  getSlugFaq(slug: Array<string>): string {
    if (this.trans.params().locale === 'it') {
      slug[0] = 'supporto'
    } else {
      slug[0] = 'support'
    }
    return `/${slug.join('/')}`;
  }

}
