export abstract class ProductManager {

  abstract payload: any;

  products: Array<any>;
  page: number = 1;

  constructor() {}

  get hasMoreProducts() {
    return this.products && this.payload.products && this.products.length < this.payload.products.length;
  }

  more() {
    this.page += 1;
    this.products = this.products.concat(this.getPagedProducts());
  }

  getPagedProducts() {
    const elements = Object.assign([], this.payload.products).splice(
      (this.payload.limit || 20) * (this.page - 1),
      (this.payload.limit || 20)
    );
    return elements;
  }

}
