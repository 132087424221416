import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { config } from "src/environments/environment";

interface CartInterface {
  [x: string]: any;
  token: string;
  products: Array<any>;
}
export interface CartElementInterface {
  product: string;
  subProducts: Array<string>;
}

@Injectable({
  providedIn: "root",
})
export class CartService {
  private _open: boolean = false;
  private basePath: string;

  constructor(private http: HttpClient) {
    this.basePath = `${config.api.protocol}://${config.api.domain}${
      config.api.port === 80 || config.api.port === 443
        ? "/api/v1/website"
        : `:${config.api.port}/api/v1/website`
    }`;
  }

  get isOpen(): boolean {
    return this._open;
  }

  open(): void {
    this._open = true;
  }

  close(): void {
    this._open = false;
  }

  toggle(): void {
    this._open = !this._open;
  }

  generate(): CartInterface {
    const token = [0, 0, 0, 0, 0, 0, 0, 0]
      .map((_) => Math.random().toString(36))
      .join("")
      .replace(/\./g, "");
    const products = [];
    const cart = { token, products };
    localStorage.setItem("cart", JSON.stringify(cart));
    return this.get();
  }

  get(): CartInterface {
    return localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : null;
  }

  get exists(): boolean {
    return !!this.get();
  }

  add(element: CartElementInterface): void {
    const cart = this.get();
    cart.products.push(element);
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  remove(i: number): void {
    const cart = this.get();
    cart.products.splice(i, 1);
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  empty(): void {
    const cart = this.get();
    cart.products = [];
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  checkout(data) {
    return this.http.post(`${this.basePath}/cart`, data);
  }
}
