import { Injectable, NgZone } from '@angular/core';
import { config } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class I18nService {
  /**
   * app default locale
   */
  defaultLocale = config.i18n.defaultLocale;

  /**
   * Array of all availabe locales
   */
  locales = config.i18n.availableLocales;

  /**
   * Current app locale
   */
  locale = "";
  private _locale = new BehaviorSubject<string>("");

  constructor(private ngZone: NgZone) {
    console.log("Initliazing I18nService");
    if (!!window.localStorage.locale) {
      console.log("Detected local storage locale", window.localStorage.locale);
      this.setLocale(window.localStorage.locale);
    } else {
      console.log("Detected browser locale", navigator.language);
      this.setLocale(navigator.language);
    }
  }

  setLocale(locale: String) {
    const loc = locale.split("-");
    if (this.locales.includes(loc[0])) {
      console.log("Setting locale to", loc[0]);
      this.locale = loc[0];
    } else {
      console.warn("Setting locale failed, fallback to", this.defaultLocale);
      this.locale = this.defaultLocale;
    }
    this.ngZone.runOutsideAngular(() => {
      this.ngZone.run(() => {
        window.localStorage.setItem("locale", this.locale.toString());
      });
    });
    this._locale.next(this.locale);
  }

  getLocale(setter = true) {
    let lang = this.defaultLocale;
    if (localStorage.getItem("locale")) {
      lang = localStorage.getItem("locale");
    } else if (navigator && navigator.language) {
      lang = navigator.language;
    }
    if (setter) {
      this.setLocale(lang);
    }
    return lang;
  }
}
