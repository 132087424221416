import { Component, OnInit, Input } from '@angular/core';
import { AttachmentService } from 'src/app/services/attachment.service';

@Component({
  selector: 'mae-resources-index',
  templateUrl: './resources-index.component.html',
  styleUrls: ['./resources-index.component.scss']
})
export class ResourcesIndexComponent implements OnInit {

  @Input() payload: any = {
    title: 'Risorse',
    resources: []
  };

  limit = true;

  constructor(
    private attachmentService: AttachmentService
  ) { }

  ngOnInit() {

    const query = {};
    if (this.payload.mode === 'choose') {
      Object.assign(query, {
        params: {
          ids: this.payload.selectedAttachments.filter(res => {
            return res.selected;
          }).map(res => {
            return res.attachment._id;
          }).join(',')
        }
      });
    } else {
      Object.assign(query, {
        params: {
          by_products: (this.payload.selectedProducts || []).map(p => p._id).join(',')
        }
      });
    }

    if (!this.payload || !this.payload.resources) {

      this.attachmentService.index(query).subscribe(res => {

        this.payload.resources = res.map(att => {
          try {
            return {
              title: att.title,
              file: att.attachment.file,
              mysqlTypeReference: att.mysqlTypeReference
            };
          } catch (e) {
            return false;
          }
        }).filter(a => a);

      }, err => {
        console.log(err);
      });
    }


  }

  get resources() {

    const tmpRes = this.payload.resources.filter(r => {
      return r.mysqlTypeReference !== 4;
    });

    if (tmpRes.length > 6) {
      if (this.limit) {
        return tmpRes.slice(0, 6);
      }
    }
    return tmpRes;
  }

  unleashLimit() {
    this.limit = !this.limit;
  }

  static get constructorName() {
    return 'ResourcesIndexComponent';
  }

}
