import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { entryComponents } from '../../components';

@Component({
	selector: 'mae-component-loader',
	templateUrl: './component-loader.component.html',
	styleUrls: [ './component-loader.component.scss' ],
	entryComponents
})
export class ComponentLoaderComponent implements OnInit {
	@Input() component;
	@ViewChild('content', { read: ViewContainerRef })
	content: ViewContainerRef;

	constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

	ngOnInit() {
		this.loadComponent(this.component);
	}

	private findComponent(componentName: any): any {
		return entryComponents.find((component) => component.constructorName === componentName);
	}

	private loadComponent(component) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
			this.findComponent(component.name)
		);
		const componentRef = this.content.createComponent(componentFactory);
		componentRef.instance['payload'] = component.payload;
	}
}
