import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { ApiService } from 'src/app/services/api-service/api.service';
import { HttpClient } from '@angular/common/http';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'mae-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  page = null;

  constructor(
    private pageService: PageService,
    private apiService: ApiService,
    private http: HttpClient,
    private metaService: MetaService
  ) {

    this.pageService.show('/homepage').subscribe(res => {
      this.page = res;
      this.metaService.setMeta({
        title: this.page && this.page.title ? this.page.title : "M.A.E srl",
        keywords: this.page && this.page.title ? this.page.title : "M.A.E srl",
        description: this.page && this.page.abstract ? this.page.abstract : "",
        image: this.page && this.page.mainImage ? this.page.mainImage : "",
      });
    }, err => {
      console.log(err);
    });
  }

  getFile() {
    this.http.get('http://localhost:3000/api/v1/website/it/products/static', {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map(res => {
        const cd = res.headers.get('Content-Disposition');
        const fileName = cd.split('filename=')[cd.split('filename=').length - 1].replace(/\"/g, '');
        return {
          content: res.body,
          fileName
        };
      })
    ).subscribe(res => {

      const blob = new Blob([res.content], { type: 'application/octet-stream' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download = res.fileName;
      debugger
      link.click();

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);

    }, err => {
      console.log(err);
    });
  }

  ngOnInit() {

  }

}
