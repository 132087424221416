import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mae-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  static get constructorName() {
    return 'TextComponent';
  }

}
