import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'mae-components-loader',
	templateUrl: './components-loader.component.html',
	styleUrls: [ './components-loader.component.scss' ]
})
export class ComponentsLoaderComponent implements OnInit {
	@Input() components = [];

	constructor() {}

	ngOnInit() {}
}
