export function DDebounce(time: number) {
  let t: any = 0;
  return function (target: Object, propertyName: string, propertyDesciptor: PropertyDescriptor): PropertyDescriptor {

    const method = propertyDesciptor.value;

    propertyDesciptor.value = function (...args: any[]) {

      clearTimeout(t);
      t = setTimeout(() => {
        method.apply(this, args);
      }, time);

    };

    return propertyDesciptor;

  };
}
