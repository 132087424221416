import { Injectable } from '@angular/core';
import { config } from '../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    protected sanitizer: DomSanitizer
  ) { }

  get baseMediaUrl(): string {

    let url = `${config.api.protocol}://${config.api.domain}`;
    if (!config.api.port || (config.api.port !== 80 && config.api.port !== 443)) {
      url += `:${config.api.port}`;
    }
    return url;

  }

  mediaUrl(path: string): string {
    return `${this.baseMediaUrl}/${path}`;
  }

  sanitizedMediaUrl(path: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.baseMediaUrl}/${path}`);
  }

  sanitizedBackgroundUrl(path: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.baseMediaUrl}/${path})`);
  }

}
