import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UInputComponent } from './u-input.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  exports: [UInputComponent]
})
export class UInputModule { }
