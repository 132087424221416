import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'mae-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  @Input() payload: any = {
    title: 'Sismica',
    text: 'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur blandit tempus porttitor.',
    textColor: 'white',
    image: {
      file: {
        path: 'http://placehold.it/1920x530'
      }
    },
  };

  constructor(
    private imageService: ImageService
  ) { }

  ngOnInit() {
  }

  scroll() {
    window.scrollTo({
      top: window.innerHeight - document.getElementById('header').clientHeight,
      behavior: 'smooth'
    });
  }

  getPath(path: string) {
    return this.imageService.sanitizedBackgroundUrl(path);
  }

  static get constructorName() {
    return 'HeroComponent';
  }

}
