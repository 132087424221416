import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Swiper from 'swiper';
import { config } from '../../../../../environments/environment';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'mae-big-hero',
  templateUrl: './big-hero.component.html',
  styleUrls: ['./big-hero.component.scss'],
})
export class BigHeroComponent implements OnInit, AfterViewInit {

  @Input() payload: any;

  slider: any = null;
  randomId = Math.floor(Math.random() * 500);

  constructor(
    private imageService: ImageService
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    if (this.payload.slides.length > 1) {
      this.slider = new Swiper('#swiper-' + this.randomId, {
        // direction: 'vertical',
      });
    }
  }

  static get constructorName() {
    return 'BigHeroComponent';
  }

  changeSlide(i) {
    this.slider[0].slideTo(i);
  }

  getActive(i) {
    try {
      return this.slider[0].activeIndex;
    } catch {
      return 0;
    }
  }

  scroll() {
    window.scrollTo({
      top: window.innerHeight - document.getElementById('header').clientHeight,
      behavior: 'smooth'
    });
  }

  getPath(path: string) {
    return this.imageService.sanitizedBackgroundUrl(path);
  }


}
