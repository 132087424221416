import { ApiService } from './api.service';

export abstract class Restify {

  abstract basePath: string;

  constructor(
    protected apiService: ApiService
  ) {

  }

  index() {
    return this.apiService.get(this.basePath);
  }
  get(id) {
    return this.apiService.get(`${this.basePath}/${id}`);
  }
  delete(id) {
    return this.apiService.delete(`${this.basePath}/${id}`);
  }
  update(id, data) {
    return this.apiService.patch(`${this.basePath}/${id}`, data);
  }
  create(data) {
    return this.apiService.post(this.basePath, data);
  }

}
