import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { UIRouterModule } from '@uirouter/angular';
import { UInputModule } from 'src/app/components/u-input/u-input.module';
import { PipesModule } from 'src/app/pipes/pipes.module'
import { FormsModule } from '@angular/forms';
import { FeedbackModule } from 'src/app/components/feedback/feedback.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CartComponent
  ],
  imports: [

    CommonModule,
    UInputModule,
    FeedbackModule,
    TranslateModule,
    FormsModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'app.cart',
          url: '/cart',
          component: CartComponent
        }
      ]
    }),
    PipesModule

  ]
})
export class CartModule { }
