import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddToCartComponent } from './add-to-cart.component';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddToCartComponent],
  imports: [
    CommonModule,
    UIRouterModule,
    TranslateModule
  ],
  exports: [AddToCartComponent]
})
export class AddToCartModule { }
