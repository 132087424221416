import { Component, OnInit, Input } from "@angular/core";
import { MenuService } from "src/app/services/menu.service";
import { AttachmentService } from "src/app/services/attachment.service";
import { nextContext } from "@angular/core/src/render3";

@Component({
  selector: "mae-attachment-index",
  templateUrl: "./attachment-index.component.html",
  styleUrls: ["./attachment-index.component.scss"]
})
export class AttachmentIndexComponent implements OnInit {
  [x: string]: any;
  @Input() payload: any = {};

  component: any;
  limit: number = 10;


  constructor(
    private attachmentService: AttachmentService,

  ) {}

  static get constructorName() {
    return "AttachmentsIndexComponent";
  }

  ngOnInit() {

    if (!this.payload.limit) {
      this.payload.limit = 10;
    }

    if(this.payload.selectedFilterType === "byChoosing" && this.payload.selectedAttachment){
      this.attachmentService.index({
        order: this.payload.order,
        ids: this.payload.selectedAttachment.map(obj => obj && obj._id ? obj._id : obj)
      }).subscribe( attachments => {
        this.payload.attachments = attachments;
        this.chooseLayout();
      },
      err => {
        console.log(err);
      }
    );
    } else {

      let params = {
        limit: this.payload.limit,
        order: this.payload.order
      }
      if (this.payload.match) {
        if (this.payload.match.category) {
          params['category'] = this.payload.match.category;
        }
      }


      this.attachmentService.index(params).subscribe(
        attachments => {
          this.payload.attachments = attachments;
          this.chooseLayout();
        },
        err => {
          console.log(err);
        }
      );
    }
    }





  private chooseLayout() {
    const payload = this.payload;

    switch (this.payload.layout) {
      // case "grid":
      //   this.component = { name: "AttachmentsIndexGridComponent", payload };
      //   break;
      // case "slider":
      //   this.component = { name: "AttachmentsIndexSliderComponent", payload };
      //   break;
      // case "table":
      //   this.component = { name: "AttachmentsIndexTableComponent", payload };
      //   break;
      default:
        this.component = { name: "AttachmentsIndexListComponent", payload, attachments: this.attachments };
        break;
    }
  }
}
