import { Component, OnInit, Input } from '@angular/core';
import { Transition } from '@uirouter/core';
import { MenuService } from 'src/app/services/menu.service';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'mae-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  @Input() page;

  @Input() products;
  searchedProducts: any = null;

  hasProductIndex = false;
  autoImport = false;

  payloads = {
    hero: null,
    html: null,
    products: null
  };

  constructor(
    private transition: Transition,
    private menuService: MenuService,
    private metaService: MetaService
  ) {
    this.hasProductIndex = !!this.transition.params().hasProductIndex;
  }

  ngOnInit() {

    if (this.products) {
      this.setupProductTemplate();
    } else {
      this.setUpPageTemplate();
    }

    this.metaService.setMeta({
      title: this.page && this.page.title ? this.page.title : "M.A.E srl",
      keywords: this.page && this.page.title ? this.page.title : "M.A.E srl",
      description: this.page && this.page.abstract ? this.page.abstract : "",
      image: this.page && this.page.mainImage ? this.page.mainImage : "",
    });
  }

  private setupProductTemplate() {
    this.searchedProducts = {
      title: `${this.products.length} Prodotti Trovati`,
      layout: 'table',
      products: (this.products || [])
    };
  }

  private setUpPageTemplate() {
    if (!this.page.partials.length && this.transition.params().autoImport) {
      this.autoImport = true;

      if (this.page.mainImage) {
        this.payloads.hero = {
          title: this.page.title,
          text: '',
          textColor: 'white',
          image: this.page.mainImage,
        };
      }

      if (this.page.description) {
        this.payloads.html = {
          text: this.page.description
        };
      }

      let tax = this.transition.params().taxonomy;
      const cat = this.transition.params().category;
      const slug = this.transition.params().slug;

      if (tax === 'sismica') {
        tax = 'seismic';
      } else if (tax === 'geoelettrica') {
        tax = 'geoelectric';
      } else if (tax === 'monitoraggio') {
        tax = 'monitoring';
      }

      const taxonomies = this.menuService.getTaxonomy(tax);

      if (cat === 'methodologies' || cat === 'metodologie') {

        const element = taxonomies.methodologies.sections.find(section => {
          return section.page.slug === slug;
        });

        if (element) {

          this.payloads.products = {
            layout: 'list',
            match: {
              methodologies: element._id
            },
            methodologies: element._id,
            title: 'Prodotti',
            limit: 5
          };

        }

      } else {

        const element = taxonomies.categories.sections.find(section => {
          return section.page.slug === slug;
        });

        if (element) {

          this.payloads.products = {
            layout: 'list',
            match: {
              category: element._id
            },
            category: element._id,
            title: 'Prodotti',
            limit: 5
          };

        }

      }

    }
  }

}
