import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';
import { ProductManager } from '../product-manager';

@Component({
  selector: 'mae-products-index-grid',
  templateUrl: './products-index-grid.component.html',
  styleUrls: ['./products-index-grid.component.scss']
})
export class ProductsIndexGridComponent extends ProductManager implements OnInit {

  @Input() payload: any = {};

  constructor(
    private imageService: ImageService
  ) {
    super();
  }

  ngOnInit() {
    this.products = this.getPagedProducts();
  }

  getUrl(path: string): string {
    return this.imageService.mediaUrl(path);
  }

  static get constructorName() {
    return 'ProductsIndexGridComponent';
  }

}
