import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'mae-product-hero',
  templateUrl: './product-hero.component.html',
  styleUrls: ['./product-hero.component.scss']
})
export class ProductHeroComponent implements OnInit {

  @Output() triggerAddToCart: EventEmitter<any> = new EventEmitter();
  @Input() payload: any = {};

  constructor(
    private imageService: ImageService,
    private cartService: CartService
  ) { }

  ngOnInit() { }

  scroll() {
    window.scrollTo({
      behavior: 'smooth',
      top: window.innerHeight,
    });
  }

  getUrl(path: string): string {
    return this.imageService.mediaUrl(path);
  }

  openAddToCart($event) {
    $event.preventDefault();
    this.cartService.open();
  }

}
