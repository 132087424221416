import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mae-u-radio',
  templateUrl: './u-radio.component.html',
  styleUrls: ['./u-radio.component.scss']
})
export class URadioComponent {

  @Input() model: any;
  @Input() name: string;
  @Input() text: string;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() activeRadio: boolean;
  @Input() ref: string;
  @Input() params: any;

  @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() linkClick: EventEmitter<any> = new EventEmitter<any>();

  initialize = true;

  constructor() { }

  onChange() {
    this.modelChange.emit(this.model);
  }

  onClick() {
    this.linkClick.emit();
  }

  onReset() {

    this.initialize = false;
    this.model = null;

    setTimeout(() => {
      this.initialize = true;
      this.onChange();
      this.change.emit();
    }, 5);

  }

}
