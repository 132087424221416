import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';
import { ProductManager } from '../product-manager';
import { Transition } from '@uirouter/angular'

@Component({
  selector: 'mae-products-index-list',
  templateUrl: './products-index-list.component.html',
  styleUrls: ['./products-index-list.component.scss']
})
export class ProductsIndexListComponent extends ProductManager implements OnInit {

  @Input() payload: any = {};
  @Input() hasSpecifics: boolean

  constructor(
    private imageService: ImageService,
    public trans: Transition
  ) {
    super();
  }
  defaultImageLinked: any;
  ngOnInit() {
    this.products = this.getPagedProducts();
  }

  getQty(product) {
    return product.qty
  }

  scrollToSpecific(element: any) {
    window.scrollTo({
      behavior: 'smooth',
      top: document.getElementById('specification-0').offsetTop - 300
    })
  }

  getUrl(path: string): string {
    return this.imageService.mediaUrl(path);
  }

  static get constructorName() {
    return 'ProductsIndexListComponent';
  }

}
